import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import TabProduct from "../components/TabProduct";
import { useSelector } from "react-redux";

const priceFilterItems = [
  { id: 1, name: "Under BDT 50", minPrice: 0, maxPrice: 50 },
  { id: 2, name: "BDT 50 to BDT 100", minPrice: 50, maxPrice: 100 },
  { id: 3, name: "BDT 100 to BDT 200", minPrice: 100, maxPrice: 200 },
  { id: 4, name: "BDT 200 to BDT 300", minPrice: 200, maxPrice: 300 },
  { id: 5, name: "BDT 300 to BDT 500", minPrice: 300, maxPrice: 500 },
  { id: 6, name: "BDT 500 to BDT 1000", minPrice: 500, maxPrice: 1000 },
  { id: 7, name: "Over BDT 1000", minPrice: 1000, maxPrice: 100000 },
];

const SearchResults = ({ products }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query");

  const { items: brands } = useSelector((state) => state.brands);
  const { items: categories } = useSelector((state) => state.category);

  const [filteredProducts, setFilteredProducts] = useState(products);

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState(null);
  const [sortOrder, setSortOrder] = useState("");
  const [showFilters, setShowFilters] = useState(false);

  const handleBrandChange = (brandName) => {
    setSelectedBrands((prev) =>
      prev.includes(brandName)
        ? prev.filter((name) => name !== brandName)
        : [...prev, brandName]
    );
  };

  const handleCategoryChange = (categoryName) => {
    setSelectedCategories((prev) =>
      prev.includes(categoryName)
        ? prev.filter((name) => name !== categoryName)
        : [...prev, categoryName]
    );
  };

  const handlePriceRangeChange = (range) => {
    if (!selectedPriceRange || selectedPriceRange.id !== range.id) {
      setSelectedPriceRange(range);
    } else {
      setSelectedPriceRange(null);
    }
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  };

  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  useEffect(() => {
    let filtered = [...products];

    if (query) {
      filtered = filtered?.filter((product) =>
        product.name.toLowerCase().includes(query.toLowerCase())
      );
    }

    if (selectedBrands.length > 0) {
      filtered = filtered?.filter((product) =>
        selectedBrands.includes(product.brand_name)
      );
    }

    if (selectedCategories.length > 0) {
      filtered = filtered?.filter((product) =>
        selectedCategories.includes(product.category_name)
      );
    }

    if (selectedPriceRange) {
      filtered = filtered.filter(
        (product) =>
          +product.unit_price >= selectedPriceRange.minPrice &&
          +product.unit_price <= selectedPriceRange.maxPrice
      );
    }

    if (sortOrder === "lowToHigh") {
      filtered.sort((a, b) => a.unit_price - b.unit_price);
    } else if (sortOrder === "highToLow") {
      filtered.sort((a, b) => b.unit_price - a.unit_price);
    }

    setFilteredProducts(filtered);
  }, [
    query,
    products,
    selectedBrands,
    selectedCategories,
    sortOrder,
    selectedPriceRange,
  ]);

  return (
    <div className="container mx-auto my-5 px-10 min-h-[100vh - 160px] relative  ">
      {showFilters && (
        <div
          onClick={toggleFilters}
          className="fixed top-0 left-0 right-0 bottom-0 h-full bg-black/20 z-[9998] "
        ></div>
      )}
      <h2 className="text-darkGray text-3xl ">
        Search Results for <span className="font-semibold">"{query}"</span>
      </h2>

      {
        <div className="search-results h-full flex">
          <div
            className={`fixed z-[9999] overflow-y-scroll lg:z-[9996] top-0 left-0 h-[70rem] w-72 lg:w-full search-results-left  bg-lightColor p-4 shadow-lg transform transition-transform duration-300  ${
              showFilters ? "translate-x-0" : "-translate-x-full"
            } lg:static lg:translate-x-0 `}
          >
            <div className="flex items-center justify-between">
              <h2 className="text-[1.8rem] font-semibold">Search Filter</h2>
              <span
                onClick={toggleFilters}
                className="lg:hidden text-2xl font-semibold cursor-pointer"
              >
                x
              </span>
            </div>

            {/* Brand Filter */}
            {brands.length > 0 && (
              <div className="flex flex-col gap-1 mt-5">
                <h1 className="text-lg font-semibold">Brand</h1>
                <div className="flex flex-col gap-2">
                  {brands?.map((brand) => (
                    <div className="flex gap-2" key={brand.id}>
                      <input
                        type="checkbox"
                        value={brand.name}
                        onChange={() => handleBrandChange(brand.name)}
                      />
                      <span>{brand.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Category Filter */}
            {categories?.length > 0 && (
              <div className="flex flex-col gap-1 mt-5">
                <h1 className="text-lg font-semibold">Category</h1>
                <div className="flex flex-col gap-2">
                  {categories?.map((cat) => (
                    <div className="flex gap-2" key={cat.id}>
                      <input
                        type="checkbox"
                        value={cat.name}
                        onChange={() => handleCategoryChange(cat.name)}
                      />
                      <span>{cat.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Price Range Filter */}
            <div className="flex flex-col gap-1 mt-5">
              <h1 className="text-lg font-semibold">Price Range</h1>
              <div className="flex flex-col gap-2">
                {priceFilterItems.map((price) => (
                  <div className="flex gap-2" key={price.id}>
                    <input
                      type="checkbox"
                      checked={selectedPriceRange?.id === price.id}
                      onChange={() => handlePriceRangeChange(price)}
                    />
                    <span>{price.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Search Results Right */}
          <div className="search-results-right w-full flex flex-col gap-2 ">
            <div className="top-bar  flex flex-col lg:flex-row items-start lg:items-center justify-between ">
              <h3 className="text-4xl font-semibold">Shop</h3>

              {/* Sort Order */}
              <div className="flex flex-col md:flex-row items-start md:items-center gap-5">
                <div className="filter-btn mt-5  lg:hidden ">
                  <span
                    onClick={toggleFilters}
                    className="px-5 py-3 font-semibold duration-300 bg-darkGray/10 hover:bg-darkGray/20 rounded-md cursor-pointer whitespace-nowrap"
                  >
                    Filter By
                  </span>
                </div>
                <div className="flex flex-col gap-1 mt-5">
                  <h1 className="text-lg font-semibold">Sort By</h1>
                  <select
                    className="w-56"
                    onChange={handleSortOrderChange}
                    value={sortOrder}
                  >
                    <option value="">Select</option>
                    <option value="lowToHigh">Price: Low to High</option>
                    <option value="highToLow">Price: High to Low</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-4">
              {filteredProducts.length > 0 ? (
                filteredProducts?.map((product) => (
                  <TabProduct key={product.id} product={product} />
                ))
              ) : (
                <p className="text-center col-span-full">No products found.</p>
              )}
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default SearchResults;
