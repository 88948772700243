import React from "react";

const SectionTitle = ({ title, handleActiveChange, activeTab, index }) => {
  return (
    <h2
      onClick={() => handleActiveChange(index)}
      className={` ${
        activeTab === index ? "text-gray-700" : "text-darkGray"
      } text-2xl cursor-pointer`}
    >
      {title}
    </h2>
  );
};

export default SectionTitle;
