export const sectiontitle = [
  {
    id: 1,
    name: "Trending",
    slug: "trending",
  },
  {
    id: 2,
    name: "Best Selling",
    slug: "best-selling",
  },
  {
    id: 3,
    name: "New Arrivals",
    slug: "new-arrival",
  },
  {
    id: 4,
    name: "Featured",
    slug: "featured",
  },
];
