import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  items: null,
  loading: false,
};

//data fetching

export const trendingFetching = createAsyncThunk(
  "trendingproducts/trendingFetching",
  async () => {
    const res = await axios.get(`${process.env.REACT_APP_URL}/api-trending`);
    return res.data;
  }
);

export const trendingSlice = createSlice({
  name: "trendingproducts/trendingFetching",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(trendingFetching.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(trendingFetching.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload;
    });
    builder.addCase(trendingFetching.rejected, (state, action) => {
      state.loading = true;
      state.status = "Something Went Wrong";
    });
  },
});

export default trendingSlice.reducer;
