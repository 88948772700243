import { Link, NavLink, useNavigate } from "react-router-dom";
import { MdOutlineCall } from "react-icons/md";

import { useSelector } from "react-redux";

import { BsCart3 } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";

import {
  FaFacebook,
  FaInstagram,
  FaRegUserCircle,
  FaTiktok,
} from "react-icons/fa";
import { useState } from "react";

const Navbar = ({ handleMenu }) => {
  const { items: data } = useSelector((state) => state.category);
  const { cartItems: product } = useSelector((state) => state.cart);
  const { items: logo } = useSelector((state) => state.logo);

  const { userAndToken: userData } = useSelector((state) => state.auth);

  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?query=${searchQuery}`);
    }
    setSearchQuery("");
  };
  return (
    <>
      <div className="sticky  md:px-0 -top-[32px] md:top-0 md:left-0 shadow-md  md:right-0 z-[9998] ">
        <div className={` bg-accentColor  text-lightColor py-1 `}>
          <div className="container mx-auto w-full flex justify-between px-10 ">
            <div className="flex gap-1 items-center ">
              <MdOutlineCall />
              <span>
                <a href="tel://">+8801870000000</a>
              </span>
            </div>
            <div className="flex items-center">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-400 mr-4"
              >
                <FaFacebook />
              </a>
              <a
                href="https://www.tiktok.com/en/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:hover:text-white mr-4"
              >
                <FaTiktok />
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-red-400"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
        {/* middle nav */}
        <div className="bg-primaryColor py-5">
          <div className="container mx-auto flex items-center px-10 justify-between">
            {/* <Link
              to={"/"}
              className="brand  h-[3rem]  rounded-md overflow-hidden"
            >
              <img
                src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
                className="w-full h-full object-cover"
                alt=""
              />
            </Link> */}
            <Link className="flex justify-center items-center rounded-full overflow-hidden">
              <img
                src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
                alt="Logo"
                class="w-20 h-auto"
              />
            </Link>
            <form onSubmit={handleSearch} className="flex relative w-96 ">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search products..."
                className="border px-2 py-3 rounded-md w-full outline-none"
              />
              <button className="search-icon absolute right-2 top-[50%] -translate-y-1/2">
                <FaSearch className="text-lightColor" />
              </button>
            </form>

            <div className=" text-lightColor  text-[0.9rem] flex items-center gap-5 duration-1000">
              {!userData && (
                <>
                  <Link
                    to={`/login`}
                    className=" hover:underline  underline-offset-2 duration-300 "
                  >
                    <FaRegUserCircle className="text-2xl text-lightColor" />
                  </Link>
                </>
              )}

              {userData && (
                <Link
                  to={`/user`}
                  className="flex items-center  justify-center gap-2"
                >
                  {!userData?.user?.avatar && (
                    <span className=" w-10 h-10 overflow-hidden rounded-full border-2 border-lightColor">
                      <img
                        src="/default_image.jpeg"
                        alt="default avatar"
                        className="w-full h-full object-cover"
                      />
                    </span>
                  )}
                  {userData?.user?.avatar && (
                    <span className=" w-12 h-12  overflow-hidden rounded-full border-2 border-light">
                      <img
                        src={`${process.env.REACT_APP_URL}/uploads/users/${userData?.user?.avatar}`}
                        alt={`${userData?.user?.name}`}
                        className="w-full h-full object-cover"
                      />
                    </span>
                  )}
                  <p className="text-[0.7rem] capitalize md:block hidden  md:text-[1.1rem]">
                    {userData?.user?.name}
                  </p>
                </Link>
              )}
              <Link to="/addtocart" className="hidden md:block  relative group">
                <BsCart3 className="text-2xl text-light" />
                <span className=" absolute bottom-3 text-[12px] left-4 bg-accentColor h-5 w-5 flex items-center justify-center rounded-full text-lightColor group-hover:bg-primaryColor group-hover:border group-hover:border-lightColor duration-300">
                  {product.length}
                </span>
              </Link>
            </div>
          </div>

          {/* search button */}
          {/* <div className="block md:hidden ">
            <form
              onSubmit={handleSearch}
              className="relative flex flex-col items-center justify-center"
            >
              <input
                ref={inputField}
                type="text"
                placeholder="Search Items......"
                onChange={handleChange}
                className=" bg-light border p-3 w-full border-red-500   rounded-md focus:outline-none"
              />
              {filterData.length === 0 ? (
                <FiSearch className="absolute right-3 text-xl text-gray-500" />
              ) : (
                <IoIosClose
                  onClick={handleClose3}
                  className="absolute right-3  text-2xl text-gray-500"
                />
              )}
              {filterData.length !== 0 && (
                <div className="search-content flex flex-col w-full z-[9999] gap-3 bg-light h-auto  p-2 overflow-hidden overflow-y-scroll">
                  {filterData.slice(0, 5).map((product) => (
                    <Link
                      onClick={handleClose3}
                      key={product.id}
                      to={`/productdetails/${product.id}`}
                      className="product-card flex items-center    gap-2"
                    >
                      <div className="product-image w-20 h-20 overflow-hidden">
                        <img
                          src={`${process.env.REACT_APP_URL}/uploads/product/${product.photos}`}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="product-content text-black w-full text-sm overflow-hidden">
                        <h4 className="font-semibold">{product?.brand_name}</h4>
                        <p className="truncate text-lg ">{product?.name}</p>
                        <span className="text-gray-400">
                          {currencyFormatter(product?.unit_price)}
                        </span>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </form>
          </div> */}
        </div>

        <div className={` bg-lightColor hidden md:block text-darkGray`}>
          <div className="container mx-auto">
            <div className="flex justify-center">
              <ul className="flex text-[0.9rem]  lg:text-[1rem] gap-5 py-2 justify-between items-center">
                <li className="relative">
                  <NavLink
                    end
                    to={"/"}
                    className={({ isActive }) =>
                      `${
                        isActive
                          ? "before:bg-accentColor before:w-full before:content-[' '] before:absolute before:left-0 before:-bottom-1 before:h-[3px]"
                          : "text-darkGray font-normal navLink "
                      }     duration-300 relative`
                    }
                  >
                    Home
                  </NavLink>
                </li>
                {data?.slice(0, 6).map((navItem) => (
                  <li key={navItem.id}>
                    <NavLink
                      className={({ isActive }) =>
                        `${
                          isActive
                            ? " before:content-[' '] before:absolute before:left-0 before:-bottom-1 before:w-full before:h-[3px] before:bg-accentColor"
                            : "text-gray font-normal"
                        } hover:text-hover navLink  duration-300 relative`
                      }
                      to={`/categorypage/${navItem.id}`}
                      key={navItem.id}
                    >
                      {navItem.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
