import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import { useSelector } from "react-redux";
import HomeLayout from "./components/layout/HomeLayout";
import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import AddToCart from "./pages/AddToCart";
import Checkout from "./pages/Checkout";
import NotFound from "./pages/NotFound";
import SuccessPage from "./pages/SuccessPage";

import CategoryPage from "./pages/CategoryPage";

import LandingPage from "./pages/LandingPage";

import SubCategoryPage from "./pages/SubCategoryPage";
import "react-toastify/dist/ReactToastify.css";
import LandingLayout from "./components/layout/LandingLayout";
import UserLayout from "./components/layout/UserLayout";
import UserProfile from "./pages/user/UserProfile";
import UserOrder from "./pages/user/UserOrder";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import TrendingProductPage from "./pages/TrendingProductPage";
import BestSellingProductPage from "./pages/BestSellingProductPage";
import NewArrivalProductPage from "./pages/NewArrivalProductPage";
import FeaturedProductPage from "./pages/FeaturedProductPage";
import SearchResults from "./pages/SearchResults";

function App() {
  const { cartItems: data } = useSelector((state) => state.cart);
  const { userAndToken: userData } = useSelector((state) => state.auth);
  const { items: productData } = useSelector((state) => state.products);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/productdetails/:id",
          element: <ProductDetails />,
        },
        {
          path: "/addtocart",
          element: <AddToCart />,
        },
        {
          path: "/checkout",
          element: data.length > 0 ? <Checkout /> : <NotFound />,
        },
        {
          path: "/successpage",
          element: <SuccessPage />,
        },

        {
          path: "/categorypage/:id",
          element: <CategoryPage />,
        },
        {
          path: "/subcategory/:id",
          element: <SubCategoryPage />,
        },
        {
          path: "/trending-product",
          element: <TrendingProductPage />,
        },
        {
          path: "/bestselling-product",
          element: <BestSellingProductPage />,
        },
        {
          path: "/newarrival-product",
          element: <NewArrivalProductPage />,
        },
        {
          path: "/featured-product",
          element: <FeaturedProductPage />,
        },
        {
          path: "/search",
          element: <SearchResults products={productData} />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
        {
          path: "/login",
          element: userData ? <Navigate to="/" /> : <Login />,
        },
        {
          path: "/register",
          element: userData ? <Navigate to="/" /> : <SignUp />,
        },
      ],
    },
    {
      path: "/landingpage",
      element: <LandingLayout />,
      children: [
        {
          path: "/landingpage/:id",
          element: <LandingPage />,
        },
      ],
    },
    {
      path: "/user",
      element: <UserLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={`/user/profile`} replace />,
        },
        {
          path: `/user/profile`,
          element: userData ? <UserProfile /> : <Navigate to="/" />,
        },
        {
          path: "/user/order",
          element: userData ? <UserOrder /> : <Navigate to="/" />,
        },
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
