import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay, HashNavigation, Navigation } from "swiper";
import { useSelector } from "react-redux";
// import { useState } from "react";
// import {
//   MdOutlineKeyboardArrowDown,
//   MdOutlineKeyboardArrowUp,
// } from "react-icons/md";
import SubCat from "../components/SubCat";

const BannerSlider = () => {
  const { items: data } = useSelector((state) => state.banners);

  // const { items: subcategories } = useSelector((state) => state.subCategor);

  return (
    <div className="container mx-auto flex justify-center mt-0  md:mt-5 ">
      <div className="flex justify-center h-[10rem] md:h-[30rem]  gap-3">
        <div className="sub-cat bg-lightColor w-[15%] hidden md:block rounded-xl border border-borderColor overflow-y-scroll ">
          <SubCat />
        </div>
        <div className="banner-slider-frame w-full md:w-[80%] rounded-xl overflow-hidden">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={30}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            speed={700}
            hashNavigation
            loop={true}
            modules={[Autoplay, Navigation, HashNavigation]}
            className="mySwiper h-full w-full"
          >
            {data.map((image) => (
              <div key={image.id}>
                <SwiperSlide>
                  <a
                    href={`${image.url}`}
                    target="_blank"
                    rel="noreferrer"
                    className="w-full  md:h-[20rem]  overflow-hidden"
                  >
                    <img
                      key={image.id}
                      src={`${process.env.REACT_APP_URL}/public/uploads/slider/${image?.slider}`}
                      alt={image.alt}
                      className={` h-full  w-full  transition-opacity duration-500 object-cover `}
                    />
                  </a>
                </SwiperSlide>
              </div>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default BannerSlider;
