import { useSelector } from "react-redux";

import TabProduct from "../components/TabProduct";

const BestSellingProductPage = () => {
  const { items: bestSelling } = useSelector((state) => state.bestSell);

  return (
    <>
      {bestSelling?.length > 0 && (
        <div className=" px-10 my-10  container mx-auto  min-h-screen">
          <div className="my-10 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
            {bestSelling?.map((product) => (
              <div key={product.id} className="overflow-hidden">
                <TabProduct product={product} />
              </div>
            ))}
          </div>
        </div>
      )}

      {bestSelling?.length === 0 && (
        <p className="text-center mt-10">No Product Found</p>
      )}
    </>
  );
};

export default BestSellingProductPage;
