import { useDispatch } from "react-redux";
import {
  addToSingleCart,
  addtoCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { FaCartPlus } from "react-icons/fa";

const TabProduct = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cartQuantity] = useState(1);

  const [colorAtr] = useState(null);
  const [sizeAtr] = useState(null);

  const addToCartHandler = (product) => {
    dispatch(addtoCart(product));
  };

  const handleBuyNow = () => {
    dispatch(removeAllFromCart());
    dispatch(
      addToSingleCart({
        ...product,
        sizeAtr,
        colorAtr,
        cartQuantity,
        variantPrice: product.discount ? product.discount : product.unit_price,
      })
    );
    navigate("/checkout");
  };

  return (
    <div className="w-full h-[24rem] bg-lightColor shadow-md rounded-md ">
      <Link
        to={`/productdetails/${product.id}`}
        className=" w-full h-[15rem] aspect-square inline-block  p-1 overflow-hidden relative "
      >
        <img
          src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
          alt={`${product.name}`}
          className="w-full h-full object-cover  overflow-hidden "
        />
        <span class="discount flex items-center justify-center text-sm font-semibold text-lightColor bg-accentColor w-10 h-6 rounded-tr-md rounded-br-md absolute top-5 left-1 ">
          2%
        </span>
      </Link>
      <div className="px-2 h-[8rem] flex bg-lightColor flex-col">
        <Link className="inline-block" to={`/productdetails/${product?.id}`}>
          <h3 className="text-[1rem] truncate   font-semibold text-[#212121]  overflow-hidden">
            {product?.name}
          </h3>
          <p className="text-[1rem] mt-2 truncate    text-gray-800  overflow-hidden">
            {product?.description}
          </p>
        </Link>
        <div className="flex mt-2 font-semibold">
          {+product.discount > 0 ? (
            <div className="flex justify-between items-center text-center gap-2">
              <span className="font-semibold line-through text-[#FA5303] decoration-200">
                ৳{product?.unit_price}
              </span>
              <span className="">৳{+product?.discount}</span>
            </div>
          ) : (
            <div className="flex items-center justify-center">
              ৳{product?.unit_price}
            </div>
          )}
        </div>
        <div className="flex  text-center  mt-2 gap-2 justify-center items-center text-gray-500">
          <button
            onClick={() => addToCartHandler(product)}
            className=" w-10 h-8 text-xl flex justify-center items-center text-lightColor rounded-md bg-accentColor"
          >
            <FaCartPlus />
          </button>

          <button
            onClick={handleBuyNow}
            className="border text-center  bg-black hover:text-slate-50 ] text-white duration-300 w-full  rounded-full py-1 "
          >
            Order Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default TabProduct;
