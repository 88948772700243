import React from "react";

import TabProduct from "./TabProduct";
const CategoryProductSection = ({ combinedData }) => {
  return (
    <>
      {combinedData?.map(
        (category) =>
          +category.featured === 1 && (
            <section key={category.id}>
              <div className="container mx-auto grid grid-cols-1 xl:grid-cols-6 items-center mb-10 px-4 md:px-10 justify-center gap-4">
                {/* Image Section */}
                <div className="relative col-span-1 xl:col-span-2 h-full  items-center">
                  <img
                    src={`${process.env.REACT_APP_URL}/uploads/category/${category?.banner}`}
                    alt={`${category.name}`}
                    className="w-full h-32 md:h-full object-cover overflow-hidden"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <h1 className="text-white text-3xl md:text-5xl font-bold">
                      Bird
                    </h1>
                  </div>
                </div>

                {/* Product Section */}
                <div className="col-span-1 xl:col-span-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                  {category?.products?.slice(0, 4).map((product) => (
                    <TabProduct key={product.id} product={product} />
                  ))}
                </div>
              </div>
            </section>
          )
      )}
    </>
  );
};

export default CategoryProductSection;
