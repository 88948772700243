import React from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

const SubCat = () => {
  const { items: category } = useSelector((state) => state.category);
  return (
    <div>
      {category?.map((cat) => (
        <Link
          to={`/categorypage/${cat.id}`}
          key={cat.id}
          className="flex gap-3 items-center px-3 py-3 border-b border-borderColor hover:text-lightColor hover:bg-hoverPrimary"
        >
          <div className="image w-[35px] h-[35px] rounded-full overflow-hidden ">
            <img
              src={`${process.env.REACT_APP_URL}/uploads/category/${cat.icon}`}
              alt={cat.alt}
              className="w-full h-full object-cover"
            />
          </div>
          <p className="text-sm lg:text-base">{cat.name}</p>
        </Link>
      ))}
    </div>
  );
};

export default SubCat;
