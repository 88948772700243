import { useEffect } from "react";

import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { singleCategoryFetching } from "../features/category/singleCategorySlice";
import { Triangle } from "react-loader-spinner";
import TabProduct from "../components/TabProduct";

const CategoryPage = () => {
  const dispatch = useDispatch();

  const { items: data, loading } = useSelector(
    (state) => state.categoryProduct
  );

  const { id } = useParams();

  useEffect(() => {
    dispatch(singleCategoryFetching(id));
  }, [dispatch, id]);

  return (
    <>
      {loading && (
        <div className="flex items-center justify-center min-h-[calc(100vh-25px)]">
          <Triangle
            visible={true}
            height="120"
            width="120"
            color="#000"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
      {data?.length > 0 && (
        <div className="my-10 px-10 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 container mx-auto xl:grid-cols-6 gap-4">
          {data?.map((product) => (
            <div key={product.id} className=" overflow-hidden">
              <TabProduct product={product} />
            </div>
          ))}
        </div>
      )}

      {data?.length === 0 && (
        <p className="text-center mt-10">No Product Found</p>
      )}
    </>
  );
};

export default CategoryPage;
