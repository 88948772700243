import { useSelector } from "react-redux";

import BannerSlider from "./BannerSlider";

import CategoryProductSection from "../components/CategoryProductSection";

import TabMenuSection from "./TabMenuSection";
import { ClipLoader } from "react-spinners";

const Home = () => {
  const { items: productData, loading } = useSelector(
    (state) => state.products
  );

  const { items: categoryData } = useSelector((state) => state.category);

  const combinedData = categoryData?.map((category) => ({
    ...category,
    products: productData?.filter(
      (product) => +product?.category_id === category?.id
    ),
  }));
  return (
    <div className="p-4 md:p-0">
      {loading ? (
        <div className="flex items-center justify-center  min-h-[calc(100vh-147px)]">
          <ClipLoader
            visible={true}
            height="120"
            width="120"
            color="#232342"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          <BannerSlider />

          <TabMenuSection />

          <CategoryProductSection combinedData={combinedData} />
        </>
      )}
    </div>
  );
};

export default Home;
