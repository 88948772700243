import { useState } from "react";
import SectionTitle from "../components/SectionTitle";
import { sectiontitle } from "../lib/sectionTitle";
import { useSelector } from "react-redux";
import TabProduct from "../components/TabProduct";
import { Link } from "react-router-dom";

const TabMenuSection = () => {
  const { items: newArrival } = useSelector((state) => state.newArrivalProduct);
  const { items: bestSelling } = useSelector((state) => state.bestSell);
  const { items: featured } = useSelector((state) => state.featuredProduct);
  const { items: trending } = useSelector((state) => state.trendingProduct);
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveChange = (index) => {
    setActiveTab(index);
  };
  return (
    <div className=" flex flex-col  w-full">
      <div className="tab-menus flex justify-center items-center gap-3 md:gap-5 my-5 flex-wrap">
        {sectiontitle?.map((menu, i) => (
          <SectionTitle
            handleActiveChange={handleActiveChange}
            activeTab={activeTab}
            key={menu.id}
            index={i}
            title={menu.name}
          />
        ))}
      </div>

      <div className="container mx-auto px-16">
        {/* trending product */}
        {activeTab === 0 && (
          <div className="flex flex-col justify-center items-center">
            <div className=" grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
              {trending?.slice(0, 14).map((product) => (
                <TabProduct key={product.id} product={product} />
              ))}
            </div>
            <Link
              to={"/trending-product"}
              className="px-4 py-2 inline-block my-5  bg-accentColor rounded-md text-lightColor "
            >
              See More
            </Link>
          </div>
        )}
        {/* best selling */}
        {activeTab === 1 && (
          <div className="flex flex-col justify-center items-center">
            <div className=" grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
              {bestSelling?.slice(0, 14).map((product) => (
                <TabProduct key={product.id} product={product} />
              ))}
            </div>
            <Link
              to={"/bestselling-product"}
              className="px-4 py-2 inline-block my-5 bg-accentColor rounded-md text-lightColor "
            >
              See More
            </Link>
          </div>
        )}

        {/* new Arrival */}
        {activeTab === 2 && (
          <div className="flex flex-col justify-center items-center">
            <div className=" grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
              {newArrival?.slice(0, 14).map((product) => (
                <TabProduct key={product.id} product={product} />
              ))}
            </div>
            <Link
              to={"/newarrival-product"}
              className="px-4 py-2 inline-block my-5 bg-accentColor rounded-md text-lightColor "
            >
              See More
            </Link>
          </div>
        )}

        {/* featured product */}
        {activeTab === 3 && (
          <div className="flex flex-col justify-center items-center">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
              {featured?.slice(0, 14).map((product) => (
                <TabProduct key={product.id} product={product} />
              ))}
            </div>
            <Link
              to={"/featured-product"}
              className="px-4 py-2 inline-block my-5 bg-accentColor rounded-md text-lightColor "
            >
              See More
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default TabMenuSection;
